import css from './Architecture.module.scss'
import Link from "next/link"
import {useEffect, useRef, useState} from "react";
import useVisible from "../../../hooks/useVisible";
import {SelectImage} from "../../../actions/util"

const Architecture = ({title, name, text, url, image}) => {

    //animation
    const ref = useRef(null)
    const {isVisible} = useVisible(ref)
    //

    const [visible, setVisible] = useState(false)
    const [src, setSrc] = useState('')

    const img = useRef(null)
    const img_data = SelectImage("/files/images/main/main-arch.jpg", image)

    useEffect(() => {
        isVisible && setSrc(img.current.getAttribute('data-src'))
    }, [isVisible])

    const loadImage = () => {
        setVisible(true)
    }

    return (
        <section className={css.architecture} ref={ref}>
            <div className={css.item} >
                <img src={img_data} data-src={img_data} className={visible && isVisible ? `` : `${css.start}`} alt={""} ref={img} onLoad={() => loadImage()}/>
            </div>
            <div className={css.item} >
                <div className={visible && isVisible ? `${css.cell}` : `${css.cell} ${css.start}`}>
                    <h2 className='main-page__title main-page__architecture' dangerouslySetInnerHTML={{__html: title}}></h2>
                    <div dangerouslySetInnerHTML={{__html: text}}></div>
                    <Link href={`/architecture`}><a className={`${css.button} btn`}>{name}</a></Link>
                </div>
            </div>
        </section>
    )

}

export default Architecture