import css from './Lobby.module.scss'
import Link from "next/link"
import {useEffect, useRef, useState} from "react";
import useVisible from "../../../hooks/useVisible";
import {SelectImage} from "../../../actions/util"

const Lobby = ({title, name, text, url, image}) => {

    //animation
    const pic = useRef(null)
    const {isVisible: inViewPic} = useVisible(pic)
    const desc = useRef(null)
    const {isVisible: inViewText} = useVisible(desc)
    //

    const [visible, setVisible] = useState(false)
    const [src, setSrc] = useState('')

    const img = useRef(null)
    const img_data = SelectImage("/files/images/main/main-lobby-2.jpg", image)

    useEffect(() => {
        inViewPic && setSrc(img.current.getAttribute('data-src'))
    }, [inViewPic])

    const loadImage = () => {
        setVisible(true)
    }

    return (
        <section className={css.lobby}>
            <div className={visible && inViewPic ? `${css.pic}` : `${css.pic} ${css.start}`} ref={pic}>
                <img src={img_data} data-src={img_data}  alt={""} onLoad={() => loadImage()} width={'1920'} height={'802'} ref={img}/>
            </div>
            <div className={visible && inViewText ? `${css.text}` : `${css.text} ${css.start}`} ref={desc}>
                <h2 className='main-page__title main-page__lobby' dangerouslySetInnerHTML={{__html:title}}></h2>
                <div dangerouslySetInnerHTML={{__html:text}}></div>
                <Link href={`/lobby`}><a className={`${css.button} btn button--dark`}>{name}</a></Link>
            </div>
        </section>
    )
}

export default Lobby