import PromoSwiper from "./PromoSwiper";
import PromoSwiperClone from "./PromoSwiperClone";
import PromoNav from "./PromoNav";
import PromoScrollbar from "./PromoScrollbar";
import {useEffect, useState} from "react";
import PromoSwiperMobile from "./PromoSwiperMobile";
import PromoSlick from "./PromoSlick";

export default function PromoSlider({data, isIE}) {

    const [activeIndex, setActiveIndex] = useState(1);
    const [firstSwiper, setFirstSwiper] = useState(null);
    const [secondSwiper, setSecondSwiper] = useState(null);
    const [mobileSwiper, setMobileSwiper] = useState(null);
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        if (isMobile) {
            if (mobileSwiper && activeIndex !== mobileSwiper.activeIndex) {
                mobileSwiper.slideTo(activeIndex)
            }
        } else {
            if (firstSwiper && activeIndex !== firstSwiper.activeIndex) {
                firstSwiper.slideTo(activeIndex)
            }
        }

    }, [activeIndex])

    const changeView = () => {
        if (window?.innerWidth <= 700) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }

    useEffect(() => {
        changeView()
        window.addEventListener('resize', changeView)
        return () => {
            window.removeEventListener('resize', changeView)
        }
    }, [])

    if(isIE) return (
        <>
            <PromoSlick data={data} updateActive={(index) => setActiveIndex(index)}/>
            {!isMobile && <PromoScrollbar index={activeIndex} size={data.length}/>}
        </>
    )

    return (
        <>
            {isMobile ?
                <PromoSwiperMobile data={data} updateActive={(index) => setActiveIndex(index)}
                                   updateSwiper={(swiper) => setMobileSwiper(swiper)}/>
                :
                <>
                    <PromoSwiper otherSwiper={secondSwiper} data={data} updateActive={(index) => setActiveIndex(index)}
                                 updateSwiper={(swiper) => setFirstSwiper(swiper)}/>
                    <PromoSwiperClone otherSwiper={firstSwiper} data={data}
                                      updateSwiper={(swiper) => setSecondSwiper(swiper)}/>
                </>

            }
            <PromoNav active={activeIndex > data.length ? 1 : activeIndex} updateActive={(index) => setActiveIndex(index)} data={data}/>
          {!isMobile && <PromoScrollbar index={activeIndex > data.length ? 1 : activeIndex} size={data.length} />}
        </>
    )
}