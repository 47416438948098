import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore, {Autoplay} from 'swiper';

SwiperCore.use([Autoplay]);
import cssText from "./PromoText.module.scss";

export default function PromoSwiperMobile({data,  updateActive, updateSwiper}) {
    return (
        <Swiper
            className='promo-slider__glass'
            loop={true}
            spaceBetween={0}
            slidesPerView={1}
            watchSlidesVisibility={true}
            longSwipes={false}
            watchOverflow={true}
            onSwiper={(swiper) => updateSwiper(swiper)}
            autoplay={{delay: 5000, disableOnInteraction: false}}
            speed={1000}
            onActiveIndexChange={(swiper) => updateActive(swiper.activeIndex)}
        >
            {data.map(item => (
                <SwiperSlide className='promo-slider__glass-slide' key={item.id}>
                    {({isActive}) => (
                        <>
                            <div className='promo-slider__pic'>
                                <picture>
                                    <source srcSet={item.image_big} media="(min-width: 480px)" type="image/webp"/>
                                    <source srcSet={item.image_big_webp} media="(min-width: 480px)" type="image/jpeg"/>
                                    <source srcSet={item.image_mobile} type="image/webp"/>
                                    <source srcSet={item.image_mobile_webp} type="image/jpeg"/>
                                    <img className={isActive ? 'promo-slider__pic-img promo-slider__pic-img--active' : 'promo-slider__pic-img'} src={item.image_mobile_webp + '.jpg'} alt="" loading={'lazy'} width={'1920'} height={'937'}/>
                                </picture>
                            </div>
                            <div className={cssText.wrap}>
                                <div className={isActive ? `${cssText.text} ${cssText.active}` : cssText.text}>{item.text}</div>
                            </div>
                        </>
                    )}
                </SwiperSlide>
            ))}
        </Swiper>
    )
}
