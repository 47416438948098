import css from './Infrastructure.module.scss'
import global from '../../../styles/Global.module.scss'
import Link from "next/link"
import {useEffect, useRef, useState} from "react";
import useVisible from "../../../hooks/useVisible";
import {SelectImage} from "../../../actions/util"

const Infrastructure = ({title, name, text, url, image}) => {

    //animation
    const ref = useRef(null)
    const {isVisible} = useVisible(ref)
    //

    const [visible, setVisible] = useState(false)
    const [visibleTwo, setVisibleTwo] = useState(false)
    const [src, setSrc] = useState('')

    const img = useRef(null)
    const img_data = SelectImage("/files/images/main/main-infr.jpg", image)

    useEffect(() => {
        isVisible && setSrc(img.current.getAttribute('data-src'))
    }, [isVisible])

    const loadImage = () => {
        setVisible(true)
        setVisibleTwo(true)
    }

    return (
        <section className={`${css.infrastructure} ${global.section}`} ref={ref}>

            <div className={isVisible ? `${css.pics}` : `${css.pics} ${css.start}`} >
                <img src={img_data} data-src={img_data} className={css.map} alt={""} ref={img} onLoad={() => loadImage()} width={'748'} height={'659'}/>
            </div>

            <div className={isVisible ? `${css.content}` : `${css.content} ${css.start}`}>
                <h2 className='main-page__title main-page__infrastructure' dangerouslySetInnerHTML={{__html:title}}></h2>
                <div dangerouslySetInnerHTML={{__html:text}}></div>
                <Link href={`${url}`}><a className={`${css.button} btn`}>{name}</a></Link>
            </div>

        </section>
    )
}

export default Infrastructure