import Layout from "../components/layout/Layout";
import Promo from "../components/main/promo/Promo";
import Offer from "../components/main/offer/Offer";
import Advantages from "../components/main/advantages/Advantages";
import About from "../components/main/about/About";
import Parameters from "../components/main/parameters/Parameters";
import Location from "../components/main/location/Location";
import Privilege from "../components/main/privilege/Privilege";
import Architecture from "../components/main/architecture/Architecture";
import Lobby from "../components/main/lobby/Lobby";
import VideoTwo from "../components/main/video/VideoTwo";
import Infrastructure from "../components/main/infrastructure/Infrastructure";
import Improvement from "../components/main/improvement/Improvement";
import Creation from "../components/main/creation/Creation";
import HeadCustom from "../components/layout/head/HeadCustom";

import dynamic from 'next/dynamic';
import { isEmpty } from "lodash";
import { GetAvailablePages } from "../actions/Header";
import {useDispatch} from "react-redux";
import {types} from "../actions/catalog";


const DynamicErrorPage = dynamic(() => import('./_error'))

export default function MainPage({ errorCode, data, seo, slides, parametersData, banner, visibility_map, isIE = false }) {
    const dispatch = useDispatch()
    dispatch({type: types.SET_IS_IE, payload: isIE})

    if (errorCode) {
        return <DynamicErrorPage statusCode={errorCode} visibility_map={visibility_map} />
    }

    return (
        <HeadCustom title={seo ? seo.title : ''} description={seo ? seo.description : ''} ogTitle={seo.og_title} ogDesc={seo.og_description} ogImg={seo.og_image}>
            <Layout visibility_map={visibility_map} isMainPage={true} isIE={isIE}>
                <h1 className='hidden-h1'>{seo ? seo.h1 : ''}</h1>
                <section className='main-page'>
                    <Promo data={data.slider} isIE={isIE} />
                    {!isEmpty(banner) && <Offer data={banner} />}
                    <About title={data.about_title} text={data.about_text} slides={data.small_slider_list}/>
                    <Advantages data={data.advantages_upper} />
                    <Parameters title={data.flat_title} number={parametersData?.number_of_flats} price_min={parametersData?.price_min} area_min={parametersData?.area_min} area_max={parametersData?.area_max} />
                    <Location auto={data.by_auto} foot={data.on_foot} title={data.location_title} text={data.location_text} url={data.location_button_url} name={data.location_button_text} image={data?.location_image} by_auto_text={data.by_auto_text} by_yorself_text={data.by_yorself_text}/>
                    <Architecture title={data.architecture_title} text={data.architecture_text} url={data.architecture_button_url} name={data.architecture_button_text} image={data?.architecture_image}/>
                    <Lobby title={data.lobby_title} text={data.lobby_text} url={data.lobby_button_url} name={data.lobby_button_text} image={data?.lobby_image}/>
                    <Infrastructure title={data.infrastructure_title} text={data.infrastructure_text} url={data.infrastructure_button_url} name={data.infrastructure_button_text} image={data?.infrastructure_image}/>
                    <Improvement title={data.improvement_title} text={data.improvement_text} url={data.improvement_button_url} name={data.improvement_button_text} image1={data?.improvement_image1} image2={data?.improvement_image2}/>
                    {/*<Creation title={data.creativity_title} text={data.creativity_text} url={data.creativity_button_url} name={data.creativity_button_text} furnishes_dict={data?.furnishes_dict}/>*/}
                    <Privilege data={data.advantages_lower} />
                    <VideoTwo video={data.bottom_video} />
                </section>
            </Layout>
        </HeadCustom>
    )
}

export async function getServerSideProps(ctx) {
    const ua = ctx.req.headers['user-agent']
    const isIE = (/trident/gi).test(ua) || (/msie/gi).test(ua);
    const res = await fetch(`${process.env.ABSOLUT_PATH}info/main/`)
    const res2 = await fetch(`${process.env.ABSOLUT_PATH}pageinfo/?url=/`)
    const res3 = await fetch(`${process.env.ABSOLUT_PATH}building/`)
    const res4 = await fetch(`${process.env.ABSOLUT_PATH}announcement/?url=/`)
    let errorCode = res.ok ? false : res.status
    const visibility_map = await GetAvailablePages()
    if (!errorCode) {
        const data = await res.json()
        const seo = res2.status === 200 ? await res2.json() : ''
        if (seo && !seo.shown) {
            errorCode = 404
            return {
                props: { errorCode, visibility_map }
            }
        }
        const banner = res4.status === 200 ? await res4.json() : {}

        //const { number_of_flats, price_min, area_min, area_max } = await res3.json()

        const parametersData = await res3.json()

        const items = [
            {
                id: 1,
                image: '/files/images/slider/promo1',
            },
            {
                id: 2,
                image: '/files/images/slider/promo3',
            },
            {
                id: 3,
                image: '/files/images/slider/promo4',
            }

        ]

        const slides = items.map((item, i) => ({
            ...item,
            text: data.slider[i].text
        }))

        // data.small_slider_list.forEach(slide => slide.path = process.env.NEXT_PUBLIC_API_LOCAL_PATH ? `${process.env.NEXT_PUBLIC_API_LOCAL_PATH}${slide.path}` : slide.path)
        // data.slider.forEach(slide => slide.image_big_webp = process.env.NEXT_PUBLIC_API_LOCAL_PATH ? `${process.env.NEXT_PUBLIC_API_LOCAL_PATH}${slide.image_big_webp}` : slide.image_big_webp)
        // data.slider.forEach(slide => slide.image_big = process.env.NEXT_PUBLIC_API_LOCAL_PATH ? `${process.env.NEXT_PUBLIC_API_LOCAL_PATH}${slide.image_big}` : slide.image_big)
        return {
            props: { errorCode, data, seo, slides, parametersData, banner, visibility_map, isIE }
        }
    } else {
        return {
            props: { errorCode, visibility_map }
        }
    }
}
